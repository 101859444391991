@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
@font-face {
  font-family: "dejima";
  font-style: normal;
  font-weight: 500;
  src: url("../font/dejima-mincho-r227.ttf") format("ttf");
  src: url("../font/dejima-mincho-r227.woff") format("woff");
  src: url("../font/dejima-mincho-r2277.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #1d1d1d;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 10px 20px;
  }
}

.inner2 {
  max-width: 1000px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_cont a, .hed_cont2 a {
  border: 2px solid #b8960c;
  padding: 10px;
  display: block;
  width: 230px;
  color: #1d1d1d;
  border-radius: 50px;
  position: relative;
  background: #fff;
}

.hed_cont a:before, .hed_cont2 a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 5%;
  top: 25%;
  color: #b8960c;
  /*アイコン色*/
}

.hed_cont {
  margin-right: 15px;
}

@media screen and (min-width: 834px) and (max-width: 1500px) {
  .hed_cont {
    margin-top: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hed_cont2 {
    margin-top: 10px;
  }
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: 0.5s ease-in-out;
  padding: 20px 30px;
  z-index: 2;
  position: relative;
}

@media only screen and (max-width: 640px) {
  #header {
    flex-flow: column-reverse;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header {
    align-items: center;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  #header {
    align-items: center !important;
    justify-content: center !important;
    flex-direction: inherit !important;
    padding: 20px 60px 0 20px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header {
    flex-flow: column-reverse;
    align-items: center;
  }
}

@media only screen and (max-width: 834px) {
  #header .hed_L {
    width: 30%;
    position: fixed;
    left: 5px;
    top: 5px;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  #header .hed_L {
    margin: 0 auto;
  }
}

#header #siteID {
  text-align: left;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header #siteID {
    text-align: center;
    margin-bottom: 10px;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

#header .hed_Lwrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header .hed_Lwrap {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 834px) and (max-width: 1500px) {
  #header .hed_Lwrap {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header .hed_Lwrap {
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  #header .hed_Lwrap {
    margin: 0 auto;
  }
}

#header .hed_Lwrap .hed_R {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 1500px) {
  #header .hed_Lwrap .hed_R {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 834px) {
  #header .hed_Lwrap .hed_R {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
    padding: 10px;
    justify-content: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header .hed_Lwrap .hed_R {
    justify-content: center;
  }
}

#header .hed_Lwrap .hed_R .tel2 {
  font-size: 1.8em;
  color: #1d1d1d;
  font-weight: 900;
  font-family: archivo-black, sans-serif;
  font-style: normal;
}

#header .hed_Lwrap .hed_R .tel2 img {
  margin-right: 7px;
}

@media only screen and (max-width: 834px) {
  #header .hed_Lwrap .hed_R .tel2 {
    justify-content: center;
  }
}

#header .hed_Lwrap .hed_R .tel2 a {
  color: #1d1d1d;
  font-weight: bold;
}

#header .hed_Lwrap .hed_R .telwrap {
  margin-right: 15px;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .hed_Lwrap .hed_R .telwrap {
    margin: 0;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 834px) {
  #header .hed_Lwrap .hed_R .telwrap {
    margin: 0;
  }
}

#header .hed_Lwrap .hed_R .telwrap .time {
  font-size: 13px;
  text-align: center;
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: rgba(250, 250, 250, 0.8);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 0 !important;
}

#nav_global {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #nav_global ul {
    justify-content: center;
  }
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
  font-family: "dejima";
}

#nav_global ul li a {
  width: 100%;
  color: #1d1d1d;
  padding: 10px;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 200px;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  left: -15%;
  right: 0;
}

#nav_global ul ul li {
  border-bottom: 1px solid #ad0837;
  font-size: 14px;
  border-bottom: none;
  margin: 15px 0;
}

#nav_global ul ul li a {
  color: #1d1d1d;
  padding: 15px 0;
}

@media only screen and (max-width: 640px) {
  #mainArea {
    margin: auto;
    padding-top: 60px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea {
    padding-top: 100px;
  }
}

.img_bnr a {
  width: 100% !important;
}

.mainwrap {
  margin-top: -94px;
}

@media only screen and (max-width: 640px) {
  .mainwrap {
    padding-top: 155px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap {
    padding-top: 190px;
  }
}

#local-keyvisual {
  position: relative;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1 {
  color: #ffffff;
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: left;
  line-height: 0.9em;
  width: 100%;
  padding: 50px 10px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 500;
  max-width: 1200px;
  margin: 0 auto;
  text-shadow: 3px 3px 6px #760727;
}

#local-keyvisual h1:empty {
  display: none;
}

#local-keyvisual h1 span {
  display: block;
  font-size: 18px;
  color: #b8960c;
  font-weight: 500;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 1.8em;
    text-align: center;
    height: 100px;
    width: 100%;
    padding: 28px;
  }
  #local-keyvisual h1 span {
    font-size: 14px;
  }
}

#slider {
  position: relative;
  z-index: 1;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

#local-keyvisual-title {
  background: #ad0837;
}

#local-keyvisual-title:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    text-align: center;
  }
}

.pan1 a {
  color: #000;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  background-size: contain;
  position: fixed;
  top: 11px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #b8960c;
  border-radius: 10px;
}

.toggleMenu:after {
  content: 'MENU';
  font-size: 13px;
  color: #b8960c;
  display: block;
  position: absolute;
  bottom: -50%;
  left: 8%;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

@media only screen and (max-width: 834px) {
  .hide {
    transform: none;
  }
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  background: #fff;
  opacity: 0;
  transition: opacity .6s ease, visibility .6s ease;
}

.fixed .overlayMenu {
  visibility: visible;
  opacity: 1;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: url(../images/home/cont3_bg2.png) left;
  background-size: cover;
  padding-top: 100px !important;
}

.overlayMenu > .outer .inner {
  padding: 0 100px;
}

.overlayMenu > .outer .sp_hed_right {
  margin-top: 80px;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right {
    display: block;
  }
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    border: 2px solid #b8960c;
    color: #1d1d1d;
    padding: 15px;
    display: block;
    max-width: 240px;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    font-size: 1em;
    padding: 10px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
}

.overlayMenu > .outer li a {
  color: #1d1d1d;
  padding: 20px;
  display: inline-block;
  font-size: 24px;
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 960px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 960px) {
  .smOnly {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
}

footer#global_footer .inner {
  padding: 50px 10px 80px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer {
    justify-content: center;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  footer#global_footer #nav_footer {
    justify-content: center;
  }
}

footer#global_footer #nav_footer li {
  text-align: left;
  font-family: "dejima";
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer li {
    text-align: center;
  }
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 15px;
  display: block;
  color: #1d1d1d;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer li a {
    padding: 10px;
    background: #ad0837;
    display: block;
    border-radius: 10px;
    margin: 5px;
    color: #ffffff;
  }
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #1d1d1d;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

#copy {
  color: #1d1d1d;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

@media only screen and (max-width: 834px) {
  .pagetop {
    bottom: 90px;
  }
}

.pagetop a {
  display: block;
  color: #ffffff;
  background: #b8960c;
  height: 60px;
  width: 60px;
  display: block;
  border-radius: 5px;
  font-size: 1.5em;
  padding: 15px;
}

#footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
  }
}

.ftlogo {
  margin: 30px 0;
}

.ft_R {
  font-size: 15px;
  line-height: 1.5em;
  text-align: left;
  margin-left: 20px;
}

@media only screen and (max-width: 834px) {
  .ft_R {
    text-align: center;
    margin-left: 0;
  }
}

.h2_01 h2 {
  font-size: 1.6em;
  color: #ad0837;
  line-height: 2em;
  font-weight: 500;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .h2_01 h2 {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 640px) {
  .h2_01 h2 {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.3em;
  }
}

.h2_02 h2 {
  font-size: 1.8em;
  color: #ad0837;
  line-height: 1.5em;
  font-weight: 500;
  position: relative;
  display: block;
  margin: 0 auto;
  width: 360px;
}

.h2_02 h2:before {
  content: url(../images/home/midashi_icon01.png);
  position: absolute;
  left: 0;
  top: -70%;
}

.h2_02 h2 span {
  font-size: 16px;
  display: block;
  color: #1d1d1d;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 640px) {
  .h2_02 h2 {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.3em;
  }
}

.h2_03 h2 {
  font-size: 1.8em;
  color: #ad0837;
  line-height: 1.5em;
  font-weight: 500;
  position: relative;
  display: block;
  margin: 0 auto;
  width: 360px;
}

.h2_03 h2:before {
  content: url(../images/home/midashi_icon02.png);
  position: absolute;
  left: 10%;
  top: -96%;
}

.h2_03 h2 span {
  font-size: 16px;
  display: block;
  color: #1d1d1d;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 640px) {
  .h2_03 h2 {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 1.3em;
  }
}

.h2_04 h2 {
  font-size: 1.8em;
  color: #ad0837;
  line-height: 1.5em;
  font-weight: 500;
  position: relative;
  display: block;
  margin: 0 auto;
  width: 250px;
}

.h2_04 h2:before {
  content: url(../images/home/midashi_icon01.png);
  position: absolute;
  left: 0;
  top: -70%;
}

.h2_04 h2 span {
  font-size: 16px;
  display: block;
  color: #1d1d1d;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 640px) {
  .h2_04 h2 {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 1.3em;
  }
}

.txt {
  line-height: 2em;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_01 {
    display: block;
    padding: 0 10px;
  }
  .sec_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 50px;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  .sec_01 {
    display: block;
    padding: 0 10px;
  }
  .sec_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 50px;
  }
}

.sec_01 .flxL {
  width: 47%;
}

.sec_01 .flxR {
  width: 51%;
  position: relative;
  padding-right: 3%;
}

@media only screen and (max-width: 834px) {
  .sec_01 .flxR {
    padding-right: 0;
  }
}

.sec_01 .flxR:before {
  content: url(../images/home/cont1_icon.png);
  position: absolute;
  right: 13%;
  top: -3%;
  z-index: -1;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    padding: 0 10px;
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  .sec_02 {
    padding: 0 10px;
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxL {
  z-index: 1;
  transform: translateX(0px);
  width: 39%;
}

.sec_02 .flxR {
  padding: 90px;
  position: relative;
}

@media screen and (min-width: 834px) and (max-width: 1040px) {
  .sec_02 .flxR {
    width: 80%;
  }
}

@media only screen and (max-width: 834px) {
  .sec_02 .flxR {
    background: url(../images/home/cont3_bg2.png) center;
    background-size: cover;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  .sec_02 .flxR {
    background: url(../images/home/cont3_bg2.png) center;
    background-size: cover;
  }
}

.sec_02 .flxR:before {
  content: url(../images/home/cont3_bg.png);
  position: absolute;
  left: -56px;
  top: 12px;
  z-index: -1;
}

@media only screen and (max-width: 834px) {
  .sec_02 .flxR:before {
    left: 90px;
    top: 12px;
    display: none;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  .sec_02 .flxR:before {
    left: 90px;
    top: 12px;
    display: none;
  }
}

.sec_02 .flxR:after {
  content: '';
  width: 700px;
  height: 550px;
  display: block;
  background: #fff;
  border: 2px solid #a0a0a0;
  left: -40px;
  top: -8px;
  position: absolute;
  z-index: -2;
}

@media only screen and (max-width: 834px) {
  .sec_02 .flxR:after {
    left: 110px;
    top: -8px;
    display: none;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  .sec_02 .flxR:after {
    left: 110px;
    top: -8px;
    display: none;
  }
}

@media screen and (min-width: 835px) and (max-width: 960px) {
  .sec_02 .flxR .txt02 div {
    text-align: center !important;
  }
}

.sec_02 .flxR .txt02 div br {
  display: block;
}

@media only screen and (max-width: 834px) {
  .sec_02 .flxR .txt02 div br {
    display: none;
  }
}

.btn a {
  text-align: center;
  color: #6f0826;
  border-radius: 50px;
  border: 2px solid #6f0826;
  padding: 10px;
  width: 240px;
  display: block;
  position: relative;
  margin: 0 auto;
}

.btn a:hover {
  background: #ad0837;
  color: #ffffff;
}

.btn a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #6f0826;
  /*アイコン色*/
}

.txt02 div {
  line-height: 2em;
}

.bnrboxwrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/home/about_acces_bg.jpg) center;
  background-size: cover;
  padding: 140px 10px;
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  width: 31%;
}

.bnrbox .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
  }
  .bnrbox .box:nth-of-type(2) {
    margin-top: 15px;
  }
  .bnrbox .box:nth-of-type(3) {
    margin-top: 15px;
  }
}

.img-animation {
  overflow: hidden;
  position: relative;
}

.img-animation.animated:after {
  -webkit-animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  background: #fff;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.newslist {
  max-height: 400px;
  overflow-y: auto;
}

.newslist li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.newslist li:nth-of-type(2n+1) {
  background: #f4ecee;
  padding: 24px;
}

.newslist li:nth-of-type(2n) {
  padding: 24px;
}

.newslist li span {
  display: block;
}

.newslist li img {
  margin-right: 30px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

/* title */
.h2_05 h2 {
  font-size: 1.8em;
  color: #ad0837;
  position: relative;
  padding: 10px 10px 10px 55px;
  line-height: 1.5em;
  border: 1px solid #ad0837;
  font-weight: 500;
}

.h2_05 h2:before {
  content: url(../images/contents/midashi_icon3.png);
  position: absolute;
  left: 1%;
  top: 18%;
}

@media only screen and (max-width: 834px) {
  .h2_05 h2:before {
    top: 10%;
  }
}

@media only screen and (max-width: 834px) {
  .h2_05 h2 {
    font-size: 1.3em;
  }
}

.h2_ger h2 {
  font-size: 1.8em;
  color: #ad0837;
  position: relative;
  padding: 10px 10px 10px 55px;
  line-height: 1.5em;
  border: 1px solid #ad0837;
  font-weight: 500;
}

.h2_ger h2:before {
  content: url(../images/contents/german_icon.png);
  position: absolute;
  left: 1%;
  top: 18%;
}

@media only screen and (max-width: 834px) {
  .h2_ger h2:before {
    top: 10%;
  }
}

@media only screen and (max-width: 834px) {
  .h2_ger h2 {
    font-size: 1.3em;
  }
}

.h2_fra h2 {
  font-size: 1.8em;
  color: #ad0837;
  position: relative;
  padding: 10px 10px 10px 55px;
  line-height: 1.5em;
  border: 1px solid #ad0837;
  font-weight: 500;
}

.h2_fra h2:before {
  content: url(../images/contents/france_icon.png);
  position: absolute;
  left: 1%;
  top: 18%;
}

@media only screen and (max-width: 834px) {
  .h2_fra h2:before {
    top: 10%;
  }
}

@media only screen and (max-width: 834px) {
  .h2_fra h2 {
    font-size: 1.3em;
  }
}

.h2_italy h2 {
  font-size: 1.8em;
  color: #ad0837;
  position: relative;
  padding: 10px 10px 10px 55px;
  line-height: 1.5em;
  border: 1px solid #ad0837;
  font-weight: 500;
}

.h2_italy h2:before {
  content: url(../images/contents/italy_icon.png);
  position: absolute;
  left: 1%;
  top: 18%;
}

@media only screen and (max-width: 834px) {
  .h2_italy h2:before {
    top: 10%;
  }
}

@media only screen and (max-width: 834px) {
  .h2_italy h2 {
    font-size: 1.3em;
  }
}

.h2_spa h2 {
  font-size: 1.8em;
  color: #ad0837;
  position: relative;
  padding: 10px 10px 10px 55px;
  line-height: 1.5em;
  border: 1px solid #ad0837;
  font-weight: 500;
}

.h2_spa h2:before {
  content: url(../images/contents/spain_icon.png);
  position: absolute;
  left: 1%;
  top: 18%;
}

@media only screen and (max-width: 834px) {
  .h2_spa h2:before {
    top: 10%;
  }
}

@media only screen and (max-width: 834px) {
  .h2_spa h2 {
    font-size: 1.3em;
  }
}

.h2_06 h2 {
  font-size: 1.8em;
  color: #ad0837;
  padding: 10px 15px;
  line-height: 1.5em;
  background: #f5eef0;
  font-weight: 500;
  border-bottom: 1px solid #ad0837;
}

.h2_06 h2 span {
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h2_06 h2 {
    font-size: 1.3em;
  }
}

.h3_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  border-bottom: 1px dotted #b8960c;
  padding-bottom: 5px;
}

.h3_02 h3 {
  font-size: 1.25em;
  font-weight: bold;
  border-bottom: 1px dotted #b8960c;
  padding-bottom: 5px;
}

.list_rec li {
  border-bottom: 1px solid #F5EEF0;
  padding: 10px;
}

.list_chuui {
  background: #f5eef0;
  padding: 30px;
}

.list_chuui li {
  position: relative;
  padding: 10px 10px 10px 50px;
  margin: 10px 0;
}

.list_chuui li:before {
  content: url(../images/flow/midashi_icon4.png);
  position: absolute;
  left: 0;
  top: 23%;
}

.txt_waku {
  background: #F5EEF0;
  padding: 30px;
}

.td_time td {
  border: none !important;
  border-bottom: 1px solid #ad0837 !important;
  background: #f5eef0;
}

.td_price td {
  width: 5%;
  font-size: 13px;
}

.td_price .td_top {
  background: #ad0837 !important;
  color: #ffffff;
  font-size: 1em;
}

.td_price .td_side {
  background: #f5eef0 !important;
  width: 10%;
  font-size: 1em;
}

.td_price2 td {
  width: 20%;
  font-size: 13px;
}

.td_price2 .td_top {
  background: #ad0837 !important;
  color: #ffffff;
  font-size: 1em;
}

.td_price2 .td_side {
  background: #f5eef0 !important;
  font-size: 1em;
}

.imgbox {
  justify-content: space-between;
}

.imgbox .box {
  width: 22%;
  text-align: center;
  background: #fff;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox .box {
    width: 47%;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 46%;
  }
}

.imgbox .box h3 {
  font-size: 1em;
  font-weight: 500;
  margin: 10px;
}

.imgbox_ger {
  justify-content: flex-start;
}

.imgbox_ger .box {
  width: 23%;
  text-align: center;
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-right: 2%;
  margin-bottom: 2%;
}

.imgbox_ger .box img {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox_ger .box {
    width: 31.333%;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox_ger .box {
    width: 48%;
  }
}

.imgbox_ger .box h3 {
  font-size: 1em;
  font-weight: 500;
  margin: 10px;
}

.bcont_01 {
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  padding: 15px;
}

@media only screen and (max-width: 834px) {
  .bcont_01 {
    display: block;
    padding: 0 10px;
  }
  .bcont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .bcont_01 .flxR {
    width: 100% !important;
    margin-top: 50px;
  }
}

.bcont_01 .flxL {
  width: 25%;
}

.bcont_01 .flxR {
  width: 75%;
}

.item_name {
  font-size: 1.5em;
  font-weight: bol;
  color: #b8960c;
}

.item_exa {
  font-size: 13px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
}

.imgbox_blog {
  justify-content: flex-start;
}

.imgbox_blog .box {
  width: 31%;
  text-align: center;
  margin-right: 2%;
  margin-bottom: 2%;
}

.imgbox_blog .box img {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox_blog .box {
    width: 31.333%;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox_blog .box {
    width: 48%;
  }
}

.list_wine li {
  background: #f5eef0;
  padding: 10px;
  margin: 10px 0;
}

.list_wine li:nth-of-type(2n) {
  background: #fff;
}

.list_wine .new:after {
  content: "NEW!";
  color: #ad0837;
  font-size: 1.25em;
  font-weight: 500;
}

.list01 li {
  background: #f7f7f7;
  padding: 10px;
  margin-bottom: 15px;
}

.contbox {
  display: flex;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .contbox {
    justify-content: center;
    padding: 0 10px;
  }
}

.contbox .box {
  width: 18vw;
  margin: 10px;
  text-align: center;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .contbox .box {
    width: 16.5%;
  }
}

.contbox .box:nth-of-type(2n) {
  margin-top: 120px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 834px) {
  .contbox .box:nth-of-type(2n) {
    margin-bottom: 0;
    margin-top: 30px;
  }
}

.contbox .box img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .contbox .box {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .contbox .box {
    width: 46%;
  }
}

.contbox .box article div {
  margin-top: 30px;
}

.contbox .box .btn {
  text-align: center;
  color: #6f0826;
  border-radius: 50px;
  border: 2px solid #6f0826;
  padding: 10px;
  width: 180px;
  font-size: 14px;
  display: block;
  position: relative;
  margin: 0 auto;
  line-height: 1em;
}

.contbox .box .btn:hover {
  background: #ad0837;
  color: #ffffff;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .contbox .box .btn {
    font-size: 80%;
    width: auto;
    max-width: 180px;
  }
}

.contbox .box .btn:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #6f0826;
  /*アイコン色*/
}

.cont_txt .back {
  background: #F0E6B5;
  padding: 10px;
  display: inline-block;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin-top: 15px;
}

.mail {
  display: none;
}

.cont_tel div {
  font-size: 1.5em;
  color: #1d1d1d;
  font-weight: bold;
}

.cont_tel {
  font-family: archivo-black, sans-serif;
}

.cont_tel .time {
  font-size: 13px;
  font-weight: 400;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.cont_tel a {
  color: #1d1d1d;
  font-weight: bold;
}

.cont_form {
  padding: 30px;
  background: #F5EEF0;
}

@media only screen and (max-width: 640px) {
  .cont_form {
    padding: 30px;
  }
}

.cont_form dl {
  border-bottom: 1px solid #fff;
  margin: 10px;
}

.dl_01 dt {
  border: none !important;
  font-weight: bold;
}

.dl_01 dd {
  border: none !important;
}

.dl_01 dl {
  border-bottom: 1px solid #b8960c;
  padding: 10px;
}

.dl_02 dt {
  border: none !important;
  font-weight: bold;
}

.dl_02 dd {
  border: none !important;
}

.dl_02 dl {
  border-bottom: 1px solid #b8960c;
  padding: 10px;
}

.qa dl {
  display: block !important;
  margin: 15px 0;
  background: #f7f7f7;
  padding: 15px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 15px 0 15px 45px !important;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #b8960c;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #F9EA48;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.gmap {
  z-index: 0;
}

.gmap iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 500px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 550px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  background-attachment: fixed;
}

@media only screen and (max-width: 834px) {
  .bg_100per_inner {
    display: block;
  }
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 1px solid #b8960c;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #b8960c;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.sns {
  margin-top: 50px;
}

#media {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#media .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#media .row .box {
  width: 31%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  #media .row .box {
    width: 100% !important;
  }
}

#media .row .box h3 {
  text-align: left;
  margin-top: 15px;
}

#media .row .box p {
  text-align: left;
}

#media .row .box img {
  width: 380px !important;
  height: 380px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
